import React, { Component } from "react";
import { payOrderSave as payOrderSaveActions } from "../../state/payOrder/actions" ;
import checkoutInfo from "../../utils/checkoutInfo";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Checkout3dsecure from "./checkouts/checkout3dsecure/checkout3dsecure";
import ErrorComponent from '../Errors/Errors';

class CheckoutInit extends Component {
  state = {
    msgError: null,
  };

  componentDidMount() {
    checkoutInfo
      .orderInfo(this.props.params.token)
      .then((result) => {
        if (result.msgError) {
          this.setState({
            msgError: result.msgError,
          });
        } else {
          document.title = `Orden de pago - ${result.commerceDetails.commerceName} - $${Number(result.payOrder.transaction.total).toFixed(2)}`
          this.props.payOrderSaveActions({
            token: this.props.params.token,
            ...result,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { commerceDetails, transactionInfo, payOrder } =
      this.props.payOrder;

    if (this.state.msgError) {
      return (
        <div className="checkoutInit">
          <ErrorComponent msgError={this.state.msgError} />
        </div>
      );
    }

    if (!commerceDetails && !transactionInfo) {
      return;
    }

    return (
        <div className="checkoutInit">
        {payOrder?.linkDetails?.idEnvironment === 1 && (
          <Helmet>
            <script src="https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js"></script>
          </Helmet>
        )}
        {payOrder?.linkDetails?.idEnvironment === 2 && (
          <Helmet>
            <script src="https://songbird.cardinalcommerce.com/edge/v1/songbird.js"></script>
          </Helmet>
        )}
        <Checkout3dsecure />
      </div>
    );
  }
};

function mapStateToProps(state) {
    return {
      payOrder: state.payOrder
    };
  }
  
  const CheckoutInitTag = connect(mapStateToProps, { payOrderSaveActions })(CheckoutInit)
  
  const WrappedComponent = (props) => {
    const params = useParams();
    return (
      <CheckoutInitTag
        {...props}
        params={params}
      />
    );
  };
  
  export default WrappedComponent;
