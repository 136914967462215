import { Card, CardContent, FormControl, TextField } from '@mui/material'
import Button from '../Button/Button'
import React, { Component, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'
import { cardStyle } from '../../hooks/useStyles'
import './dataform.scss'
import { connect } from 'react-redux'
import axios from 'axios'
import { getCurrentDate } from '../../utils/getCurrentDate'

const TextMaskCard = forwardRef(function TextMaskCard(props, ref) {
	const { onChange, ...other } = props
	return (
		<IMaskInput
			{...other}
			mask='0000 0000 0000 0000'
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name: props.name, value } })}
			overwrite
			unmask={true}
		/>
	)
})

TextMaskCard.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

const TextMaskExp = forwardRef(function TextMaskExp(props, ref) {
	const { onChange, ...other } = props
	return (
		<IMaskInput
			{...other}
			mask='00 / 00'
			inputRef={ref}
			onAccept={(value) =>
				onChange({
					target: { name: props.name, value: value.replace(/\s/g, '') },
				})
			}
			overwrite
		/>
	)
})

TextMaskExp.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

const TextMaskCvv = forwardRef(function TextMaskCvv(props, ref) {
	const { onChange, ...other } = props
	return <IMaskInput {...other} mask='000' inputRef={ref} onAccept={(value) => onChange({ target: { name: props.name, value } })} overwrite />
})

TextMaskCvv.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

class DataForm extends Component {
	state = {
		states: [],
		cities: [],
	}
	stateInput

	onHandleInputBincode = (event) => {
		const { value: bincode } = event.target
		const url = `${global.config.URL.BASE}/codesInfo/bincode/${bincode}`
		if (bincode?.length === 7) {
			axios
				.get(url)
				.then((result) => {
					let data = result.data.payload
					let country = `0-${data.country.alpha2}-${data.country.name}`
					this.props.transactionBody.country = country
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	onHandleInputZipCode = (event) => {
		const { value: zipCode } = event.target
		let country = this.props?.transactionBody?.country ? this.props.transactionBody.country.split('-') : ['', 'MX']
		const url = `${global.config.URL.BASE}/codesInfo/zipcode/${zipCode}?country=${country[1]}`
		if (zipCode?.length >= 5) {
			axios
				.get(url)
				.then((result) => {
					let states = result.data.payload.results[zipCode]
					if (states) {
						let data = states.map((value) => {
							return {
								state: value.state.substr(0, 3).toUpperCase(),
								city: value.province,
							}
						})
						this.props.transactionBody.state = `0-${data[0].state}-0`
						this.props.transactionBody.locality = `0-0-${data[0].city}`
						this.setState({ states: data[0].state, cities: data[0].city })
						this.stateInput = false
					} else {
						this.stateInput = true
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	render() {
		const { isMobile } = this.props
		return (
			<div className='card'>
				<Card sx={{ width: 850 }} style={cardStyle.customCard}>
					<CardContent style={cardStyle.customCardContent}>
						<div style={{ backgroundColor: 'rgb(218 218 218 / 30%)', padding: 24 }}>
							<p className='typography-heading'>Estás pagando</p>
							<p className='typography-amounth' style={{ marginTop: 0 }}>
								${this.props.total} <span>{this.props.currency}</span>
							</p>

							{!this.props.metadata.cuentaContrato ? (
								''
							) : (
								<p className='typography' style={{ margin: '10px 0 10px 0' }}>
									SIAPA_{this.props.metadata.cuentaContrato}_{getCurrentDate()}
								</p>
							)}
							{console.log(getCurrentDate())}

							<div className='fields-multi'>
								{!this.props.metadata.contratoName ? '' : <p className='typography-data'>{this.props.metadata.contratoName}</p>}
								{!this.props.metadata.cuentaContrato ? '' : <p className='typography-data'>#{this.props.metadata.cuentaContrato}</p>}
							</div>
						</div>
						<form style={{ padding: '24px' }}>
							<p className='typography-sub-heading'>Datos de tu tarjeta</p>

							<div className='fields'>
								<label className='label' htmlFor='cardNumber'>
									Número de tarjeta
								</label>
								<TextField
									className='form-input'
									variant='standard'
									placeholder='XXXX-XXXX-XXXX-XXXX'
									id='cardNumber'
									name='cardNumber'
									type='text'
									onInput={this.onHandleInputBincode}
									error={!this.props.inputCorrect || this.props.inputCorrect.cardNumber ? false : true}
									value={this.props.transactionBody && this.props.transactionBody.cardNumber ? this.props.transactionBody.cardNumber : ''}
									onChange={this.props.onChangeInput}
									InputProps={{
										inputComponent: TextMaskCard,
										style: cardStyle.customInput,
										disableUnderline: true,
									}}
								/>
							</div>
							{isMobile ? (
								<div className='fields'>
									<div className='row'>
										<label className='label' htmlFor='exp'>
											Expiración
										</label>
										<TextField
											variant='standard'
											className='form-input'
											placeholder='MM / AA'
											id='exp'
											name='exp'
											type='exp'
											error={!this.props.inputCorrect || this.props.inputCorrect.exp ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.exp ? this.props.transactionBody.exp : ''}
											onChange={this.props.onChangeInput}
											InputProps={{
												inputComponent: TextMaskExp,
												style: cardStyle.customInput,
												disableUnderline: true,
											}}
											required
										/>
										<label className='label' htmlFor='cvv'>
											CVV
										</label>
										<TextField
											variant='standard'
											className='form-input'
											placeholder='123'
											id='cvv'
											name='cvv'
											type='cvv'
											error={!this.props.inputCorrect || this.props.inputCorrect.cvv ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.cvv ? this.props.transactionBody.cvv : ''}
											onChange={this.props.onChangeInput}
											InputProps={{
												inputComponent: TextMaskCvv,
												style: cardStyle.customInput,
												disableUnderline: true,
											}}
										/>
									</div>
								</div>
							) : (
								<div className='fields-multi'>
									<div>
										<label className='label' htmlFor='exp'>
											Expiración
										</label>
										<TextField
											variant='standard'
											className='form-input'
											placeholder='MM / AA'
											id='exp'
											name='exp'
											type='exp'
											error={!this.props.inputCorrect || this.props.inputCorrect.exp ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.exp ? this.props.transactionBody.exp : ''}
											onChange={this.props.onChangeInput}
											InputProps={{
												inputComponent: TextMaskExp,
												style: cardStyle.customInput,
												disableUnderline: true,
											}}
											required
										/>
									</div>
									<div>
										<label className='label' htmlFor='cvv'>
											CVV
										</label>
										<TextField
											style={{ height: isMobile && '30px', fontSize: isMobile && '16px' }}
											variant='standard'
											className='form-input'
											placeholder='123'
											id='cvv'
											name='cvv'
											type='cvv'
											error={!this.props.inputCorrect || this.props.inputCorrect.cvv ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.cvv ? this.props.transactionBody.cvv : ''}
											onChange={this.props.onChangeInput}
											InputProps={{
												inputComponent: TextMaskCvv,
												style: cardStyle.customInput,
												disableUnderline: true,
											}}
										/>
									</div>
								</div>
							)}

							{this.props.address.street ? (
								''
							) : (
								<div className='fields'>
									<label className='label' htmlFor='address1'>
										Dirección
									</label>
									<input
										className='custom-form-input'
										placeholder='Av. Juárez 123'
										id='address1'
										name='address1'
										type='address1'
										error={!this.props.inputCorrect || this.props.inputCorrect.address1 ? false : true}
										value={this.props?.transactionBody?.address1 ? this.props.transactionBody.address1 : ''}
										onChange={this.props.onChangeInput}
									/>
								</div>
							)}

							<div style={{ marginTop: '10px' }}>
								{isMobile ? (
									<div className='fields'>
										<div className='row'>
											<label className='label' htmlFor='postalCode'>
												C.P.
											</label>
											<input
												className='custom-form-input'
												id='postalCode'
												name='postalCode'
												type='text'
												onInput={this.onHandleInputZipCode}
												placeholder='45060'
												error={!this.props.inputCorrect || this.props.inputCorrect.postalCode ? false : true}
												value={this.props?.transactionBody?.postalCode ? this.props.transactionBody.postalCode : ''}
												onChange={this.props.onChangeInput}
											/>

											{this.stateInput === false ? (
												<>
													<label className='label' htmlFor='state'>
														Estado
													</label>
													<FormControl fullWidth>
														<select
															disabled={true}
															className='form-input'
															id='state'
															name='state'
															error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true}
															value={this.props.transactionBody && this.props.transactionBody.state ? this.props.transactionBody.state : ''}
															onChange={this.props.onChangeInput}>
															{this.state.states ? (
																<option hidden selected>
																	{this.state.states}
																</option>
															) : (
																<option hidden selected>
																	Estado
																</option>
															)}
														</select>
													</FormControl>

													<FormControl fullWidth>
														<select
															className='form-input'
															id='locality'
															name='locality'
															error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true}
															value={this.props.transactionBody && this.props.transactionBody.locality ? this.props.transactionBody.locality : ''}
															onChange={this.props.onChangeInput}>
															{this.state.cities ? (
																<option hidden selected>
																	{this.state.cities}
																</option>
															) : (
																<option hidden selected>
																	Ciudad
																</option>
															)}
														</select>
													</FormControl>
												</>
											) : (
												<>
													<label className='label' htmlFor='state'>
														Estado
													</label>
													<input
														type='text'
														className='custom-form-input'
														placeholder='Jalisco'
														id='state'
														name='state'
														error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true}
														value={this.props.transactionBody.state ? this.props.transactionBody.state : ''}
														onChange={this.props.onChangeInput}
													/>
													<input
														type='text'
														className='custom-form-input'
														placeholder='Guadalajara'
														id='locality'
														name='locality'
														error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true}
														value={this.props.transactionBody.locality ? this.props.transactionBody.locality : ''}
														onChange={this.props.onChangeInput}
													/>
												</>
											)}
										</div>
									</div>
								) : (
									<div className='fields-multi' style={{ gap: '28px' }}>
										<div>
											<label className='label' htmlFor='postalCode'>
												C.P.
											</label>
											<input
												className='custom-form-input'
												id='postalCode'
												name='postalCode'
												type='text'
												onInput={this.onHandleInputZipCode}
												placeholder='45060'
												error={!this.props.inputCorrect || this.props.inputCorrect.postalCode ? false : true}
												value={this.props?.transactionBody?.postalCode ? this.props.transactionBody.postalCode : ''}
												onChange={this.props.onChangeInput}
											/>
										</div>

										{this.stateInput === false ? (
											<>
												<div>
													<label className='label' htmlFor='state'>
														Estado
													</label>
													<FormControl fullWidth>
														<select
															className='form-input'
															id='state'
															name='state'
															error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true}
															value={this.props.transactionBody && this.props.transactionBody.state ? this.props.transactionBody.state : ''}
															onChange={this.props.onChangeInput}
															disabled>
															{this.state.states ? (
																<option hidden selected>
																	{this.state.states}
																</option>
															) : (
																<option hidden selected>
																	Estado
																</option>
															)}
														</select>
													</FormControl>
												</div>

												<div>
													<label className='label' htmlFor='locality'>
														Ciudad
													</label>
													<FormControl fullWidth>
														<select
															className='form-input'
															id='locality'
															name='locality'
															error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true}
															value={this.props.transactionBody && this.props.transactionBody.locality ? this.props.transactionBody.locality : ''}
															onChange={this.props.onChangeInput}
															disabled>
															{this.state.cities ? (
																<option hidden selected>
																	{this.state.cities}
																</option>
															) : (
																<option hidden selected>
																	Ciudad
																</option>
															)}
														</select>
													</FormControl>
												</div>
											</>
										) : (
											<>
												<div>
													<label className='label' htmlFor='state'>
														Estado
													</label>
													<input
														type='text'
														className='custom-form-input'
														placeholder='Jalisco'
														id='state'
														name='state'
														error={!this.props.inputCorrect || this.props.inputCorrect.state ? false : true}
														value={this.props.transactionBody.state ? this.props.transactionBody.state : ''}
														onChange={this.props.onChangeInput}
													/>
												</div>
												<div>
													<label className='label' htmlFor='locality'>
														Ciudad
													</label>
													<input
														type='text'
														className='custom-form-input'
														placeholder='Guadalajara'
														id='locality'
														name='locality'
														error={!this.props.inputCorrect || this.props.inputCorrect.locality ? false : true}
														value={this.props.transactionBody.locality ? this.props.transactionBody.locality : ''}
														onChange={this.props.onChangeInput}
													/>
												</div>
											</>
										)}
									</div>
								)}

								{this.props.cardHolder.name && this.props.cardHolder.email && this.props.cardHolder.phone ? (
									''
								) : (
									<p className='typography-sub-heading'>Tus datos</p>
								)}

								{this.props.cardHolder.name ? (
									''
								) : (
									<div className='fields'>
										<label className='label' htmlFor='name'>
											Nombre completo
										</label>
										<input
											style={{ marginBottom: '14px' }}
											className='custom-form-input'
											placeholder='Ana Gómez'
											id='name'
											name='name'
											type='name'
											error={!this.props.inputCorrect || this.props.inputCorrect.name ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.name ? this.props.transactionBody.name : ''}
											onChange={this.props.onChangeInput}
										/>
									</div>
								)}

								{this.props.cardHolder.email ? (
									''
								) : (
									<div className='fields'>
										<label className='label' htmlFor='email'>
											Correo electrónico
										</label>
										<TextField
											style={{ marginBottom: '9px' }}
											variant='standard'
											placeholder='ejemplo@correo.com'
											id='email'
											name='email'
											type='email'
											InputProps={{
												style: cardStyle.customInput,
												disableUnderline: true,
											}}
											error={!this.props.inputCorrect || this.props.inputCorrect.email ? false : true}
											value={this.props.transactionBody && this.props.transactionBody.email ? this.props.transactionBody.email : ''}
											onChange={this.props.onChangeInput}
										/>
									</div>
								)}

								{this.props.cardHolder.phone ? (
									''
								) : (
									<div className='fields'>
										<label className='label' htmlFor='phoneNumber'>
											Teléfono celular
										</label>
										<input
											style={{ marginBottom: '14px' }}
											className='custom-form-input'
											placeholder='33 8524 9678'
											id='phoneNumber'
											name='phoneNumber'
											type='phoneNumber'
											error={!this.props.inputCorrect || this.props.inputCorrect.phoneNumber ? false : true}
											value={this.props?.transactionBody?.phoneNumber ? this.props.transactionBody.phoneNumber : ''}
											onChange={this.props.onChangeInput}
										/>
									</div>
								)}
							</div>

							<div className='continue-btn'>
								<Button content={`Pagar $${this.props.total}${this.props.currency}`} light={false} onClick={this.props.onClickButton} />
							</div>
						</form>
					</CardContent>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const mediaQuery = window.matchMedia('(max-width: 1200px)')
	return { isMobile: mediaQuery.matches }
}

export default connect(mapStateToProps)(DataForm)
