const dev = {
    URL: {
      BASE: "http://localhost:3005"
    },
    PRIVATE: {
      BASE: "http://localhost:3002"
    },
    ECOMMERCETRANSACTION: {
      BASE: "http://localhost:3333"
    },
    TRANSFER: {
      BASE: "http://localhost:3012"
    },
    REFERENCE: {
      SERVICENAME: 'ESPIRALCASH',
      SERVICE: '005142'
    }
  };
  
  const prod = {
    URL: {
      BASE: "https://cart.espiralapp.com"
    },
    PRIVATE: {
      BASE: "https://private.espiralapp.com"
    },
    ECOMMERCETRANSACTION: {
      BASE: "https://ecommerce-transaction-v2.espiralapp.com"
    },
    TRANSFER: {
      BASE: "https://stp.espiralapp.com"
    },
    REFERENCE: {
      SERVICENAME: 'ESPIRALCASH',
      SERVICE: '005142'
    }
  };
  
  let config = '';
  if (process.env.REACT_APP_STAGE === 'prod') {
    config = prod
  } else {
    config = dev
  }
  
  global.config = config;
  