import React from "react";
import { Card } from "@mui/material";
import { cardStyle } from '../../hooks/useStyles'
import './card.scss';

const card = (props) => {
  return (
    <Card style={cardStyle.customCard} className="smallContainer">
      {props.children}
    </Card>
  );
};

export default card;
