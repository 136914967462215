import { Component } from "react";
import { connect } from "react-redux";

import Button from '@mui/material/Button';
import "./checkoutResponse.scss";

import { formatCurrency } from '../../../../func/formatCurrency';

class CheckoutResponse extends Component {

  render() {

    return (
      <div className="checkout-response">
        <div className="container-ticket">
          {this.props?.errorTransaction && (
            <div id="errorMessageTransaction" className="containerAuthorizacion">
              {this.props?.errorTransaction}
            </div>
          )}
          <div className="containerAuthorizacion">
            Referencia
            <span id="authNumber"> 
              {this.props?.transactionResponse.reference && (
                <span>
                  {this.props.transactionResponse.reference}
                </span>
              )}
            </span>
          </div>
          <div className="container-transaction-info">
            <div>
              Comprobante
            </div>
            <div className="flex-center">
              <div id="transactionDateTicket"> 
                {this.props?.transactionResponse?.date && (
                  <span>
                    {new Date(this.props.transactionResponse.date)
                      .toLocaleString('es-MX', { timeZone: 'America/Mexico_City', dateStyle: 'short', timeStyle: 'full' })}
                  </span>
                )}
              </div>
            </div>
            <div>
              Total
            </div>
            <div className="flex-center">
              <div className="amount">$ {formatCurrency(Number(this.props.total))}</div>
            </div>
            <div>
              Tarjeta
            </div>
            <div className="flex-center">
              <div className="payment-logo">
                {(this.props?.cardType === '4') && (
                  <img className="visa" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/002_visa.svg" alt="visa" />
                )}
                {(this.props?.cardType === '5') && (
                  <img className="master" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/003_masterard.svg" alt="mc" />
                )}
                <span id="panCard"> 
                  {this.props?.transactionResponse?.pan
                    && (<span>
                      {this.props.transactionResponse.pan}
                    </span>)}
                </span>
              </div>
            </div>
            <div>
              Empresa
            </div>
            <div>
              {this.props?.payOrder?.commerceDetails?.commerceName
                && (<span>
                  {this.props?.payOrder?.commerceDetails?.commerceName}
                </span>)}
            </div>
          </div>
          <div className="ticket-footer">
            <div className="text-center img-transaction-response">
              {this.props.transactionResponse?.idTransactionStatus === 1 && (
                <img id="transactionStatus" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/016_autorizada.png" alt=""/>
              )}
              {this.props.transactionResponse?.idTransactionStatus !== 1 && (
                <img id="transactionStatus" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt=""/>
              )}
            </div>
            <div>
              {this.props.transactionInfo && this.props.transactionInfo.backPage && (
                <div className="btn-options-container">
                  <a id="cancelTransaction" href={this.props.transactionInfo.backPage}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="large"
                      onClick={this.handlePaymentBtn}
                      style={{ backgroundColor: this.props.styles.containedPrimarycolor }}>
                        Reintentar
                      </Button>
                  </a>
                </div>
              )}
            </div>
            <div className="text-center">
              <div>
                CFDI
              </div>
              <div>
                2021-2022 
                {this.props.distributorInfo && this.props.distributorInfo.distributorName && (
                  <span> {this.props.distributorInfo.distributorName} </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <svg className="image-footer-ticket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.44 18.06"><defs></defs><title>Suaje_ticket</title><g className="cls-print-ticket-1"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polygon className="cls-print-ticket-2" points="348.44 18.06 329.65 7.42 310.22 18.06 291.44 7.42 271.58 18.06 255.75 7.42 233.15 18.06 216.89 7.42 195.14 18.06 178.03 7.42 156.93 18.06 138.53 7.42 116.36 18.06 98.18 7.42 77.08 18.06 59.97 7.42 37.36 18.06 19.83 7.42 0 18.06 0 0 348.44 0 348.44 18.06"/></g></g></g></svg>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payOrder: state.payOrder
  };
}

export default connect(mapStateToProps)(CheckoutResponse);
