import React from "react";

import "./Errors.scss";

const Errors = (props) => {
  return (
    <div className="container-msg-error">
      <div className="msg-error">
        {(props.msgError === 'jwt expired') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              Orden de pago expirada
            </span>
          </div>
        )}
        {(props.msgError === 'Limites excedidos para transacciones sin 3Dsecure') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              Límite excedido sin 3Dsecure
            </span>
          </div>
        )}
        {(props.msgError === 'jwt malformed') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              JWT mal formado
            </span>
          </div>
        )}
        {(props.msgError === 'invalid signature') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              Firma invalida
            </span>
          </div>
        )}
        {(props.msgError === 'API-KEY no valida') && (
          <div>
            <div className="img-error">
              <img src="https://storage.googleapis.com/mailing-images-belugapay/carrito/017_rechazada.png" alt="Error"></img>
            </div>
            <span>
              API KEY inválida
            </span>
          </div>
        )}
      </div>
    </div>
  )
}


export default Errors;