function authentication(idCommerce, data, token) {
    const url = `${global.config.ECOMMERCETRANSACTION.BASE}/api/authentication/validateAuthenticationResults`;
    return fetch(url, {
      method: 'POST',
      headers: {
        AuthorizationIdCommerce: idCommerce,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch(err => {
        console.log(err);
      });
  }
  
  module.exports = authentication;
  