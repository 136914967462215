import React from "react";
import "./endPayment.scss";

import Card from "../smallCard/card";
import { Divider } from "@mui/material";

function EndPayment (props) {
  return (
    <Card styles={props.styles}>
      <div className="title">
        <div>
        </div>
        <div>
          <h1 className="title-name">Para terminar confirma tu pago</h1>
        </div>
      </div>
      <Divider sx={{color: '#3D3D3D'}} />
      <div className="payment">
        <div className="payment-logo">  
          {(props.cardType === '4') && (
            <img className="visa" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/002_visa.svg" alt="visa" />
          )}
          {(props.cardType === '5') && (
            <img className="master" src="https://storage.googleapis.com/mailing-images-belugapay/carrito/003_masterard.svg" alt="mc" />
          )}
        </div>
        <div className="payment-description">
          <p>Tarjeta con terminación: {props.lastFour}</p>
        </div>
        <div className="payment-modify" onClick={props.handleMod}>
          <p>Modificar</p>
        </div>
      </div>
    </Card>
  );
};

export default EndPayment;
