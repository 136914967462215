export const cardStyle = {
	customCard: {
		backgroundColor: '#f8fafb',
		paddingBottom: '24px',
	},
	customCardContent: {
		padding: 0,
	},
	customInput: {
		height: '37px',
		width: '100%',
		padding: '0 10px 0 10px',
		borderRadius: '10px',
		backgroundColor: '#FFF',
		fontSize: '16px',
		fontFamily: 'Centra Book',
		marginBottom: '10px',
		color: '#666666',
	},
	customBox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		padding: '40px',
	},
	customTypography: {
		marginTop: '5px',
		marginBottom: '5px',
		fontFamily: 'Centra Medium',
	},
	customButton: {
		background: '#732BFF',
		width: 'fit-content',
		height: '36px',
		borderRadius: '30px',
		marginTop: '15px',
		fontSize: '17px',
		color: 'white',
		padding: 12,
		textAlign: 'center',
		fontFamily: 'Centra Medium',
	},
}
