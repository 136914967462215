import React from 'react'
import './Button.scss'

const Button = ({ className, light, content, icon, iconWidth, iconHeight, iconClassName, iconPosition, disabled, onClick }) => {
	return (
		<button
			className={`btn ${light === true ? 'light' : 'dark'} ${disabled && 'disabled'} ${className}`}
			onClick={() => onClick()}
			disabled={disabled}>
			{icon && iconPosition === 'izquierda' ? (
				<img alt='icon-left' className={iconClassName} width={iconWidth} height={iconHeight} src={icon} />
			) : null}
			<span>{content}</span>
			{icon && iconPosition === 'derecha' ? (
				<img alt='icon-right' className={iconClassName} width={iconWidth} height={iconHeight} src={icon} />
			) : null}
		</button>
	)
}

Button.defaultProps = {
	type: 'primary',
	iconPosition: 'derecha',
	light: false,
	iconWidth: '30px',
	iconHeight: '30px',
}

export default Button
