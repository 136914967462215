import { Route, Routes } from 'react-router-dom';
import CheckoutInit from './components/checkout/checkoutInit';

function App() {
  return (
    <Routes>
      <Route 
        exact
        path='/:token'
        element={<CheckoutInit />}
      />
    </Routes>
  );
}

export default App;
