function sale(idCommerce, data, token) {
    const url = `${global.config.ECOMMERCETRANSACTION.BASE}/api/sale`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'AuthorizationIdCommerce': idCommerce,
        'authorization': token
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch(err => {
        console.log(err);
      });
  }
  
  module.exports = sale;
  