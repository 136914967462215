const orderInfo = require('./orderInfo');
const enrollmet = require('./enrollment');
const authentication = require('./authentication');
const sale = require('./sale');
const tokenCard = require('./tokenCard');

module.exports = {
  orderInfo,
  enrollmet,
  authentication,
  sale,
  tokenCard
};
