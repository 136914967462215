import { connect } from 'react-redux'
import CheckoutResponse from '../checkoutResponse/checkoutResponse'
import EndPayment from '../../../endPaymentCard/endPayment'
import React, { Component } from 'react'
import DataForm from '../../../dataForm/dataForm'
import Backdrop from '@mui/material/Backdrop'
import { Container } from '@mui/system'
import { enrollmet as enrollmetRequest, authentication, sale } from '../../../../utils/checkoutInfo'
import { Button, CircularProgress, Modal, Stack, Alert, Box } from '@mui/material'
import './checkout3dsecure.scss'
import { cardStyle } from '../../../../hooks/useStyles'

class Checkout3dSecure extends Component {
	state = {
		transactionBody: {},
		lastStep: false,
		cardinalComplete: false,
		inputCorrect: false,
		messageError: null,
		modalOpen: false,
		transactionResponse: null,
		paymentInformation: {
			paymentInstrument: null,
			instrumentIdentifier: null,
		},
	}
	cardinalInit = false

	constructor() {
		super()
		setTimeout(() => {
			window.Cardinal.configure({
				logging: {
					level: 'verbose',
				},
			})
		}, 5000)
	}

	InitCardinal(orderObject) {
		const context = this
		const jwt = this.props.payOrder.transactionIdentifier.jwt

		window.Cardinal.setup('init', {
			jwt: jwt,
			order: orderObject,
		})

		if (!this.cardinalInit) {
			this.cardinalInit = true
			window.Cardinal.on('payments.setupComplete', () => {
				console.log('Init Authenticate')
				this.setState({
					cardinalComplete: true,
				})
			})
			window.Cardinal.on('payments.validated', function (data, jwt) {
				console.log('Finish Authenticate')
				context.authentication({
					consumerAuthenticationInformation: {
						authenticationTransactionId: data.Payment.ProcessorTransactionId,
					},
				})
			})
		}
	}

	AuthenticateCardinal(data) {
		window.Cardinal.continue(
			'cca',
			{
				AcsUrl: data.acsUrl,
				Payload: data.pareq,
			},
			{
				OrderDetails: {
					TransactionId: data.transactionId,
				},
			}
		)
	}

	prepareTokenBodyRequest() {
		const exp = this.state.transactionBody.exp.split('/')
		return {
			paymentInformation: {
				card: {
					expirationMonth: exp[0],
					expirationYear: `20${exp[1]}`,
					number: this.state.transactionBody.cardNumber,
				},
			},
		}
	}

	prepareBodyRequest() {
		const exp = this.state.transactionBody.exp.split('/')
		const country = this.state?.transactionBody?.country ? this.state.transactionBody.country.split('-') : ['', 'MX']
		let state = ['', '', '']
		let locality = ['', '', '']
		state = this.state.transactionBody.state.split('-')
		locality = this.state.transactionBody.locality.split('-')
		return {
			orderInformation: {
				amountDetails: {
					currency: this.props.payOrder.payOrder.transaction.currency,
					totalAmount: this.props.payOrder.payOrder.transaction.total,
				},
				lineItems: this.props.payOrder.payOrder.transaction.items.map((item) => ({
					totalAmount: Number(item.quantity) * Number(item.price),
					quantity: item.quantity,
					unitPrice: item.price,
					productName: item.name,
					productDescription: item.description,
					giftCardCurrency: this.props.payOrder.payOrder.transaction.currency,
				})),
			},
			billTo: {
				address1: this.state.transactionBody.address1 || this.props.payOrder.payOrder.address.street,
				administrativeArea: state[1] || this.state.transactionBody.state,
				country: country[1],
				locality: locality[2] || this.state.transactionBody.locality,
				firstName: this.state.transactionBody.name || this.props.payOrder.payOrder.cardHolder.name,
				lastName: this.state.transactionBody.lastName || this.props.payOrder.payOrder.cardHolder.lastname,
				phone: this.state.transactionBody.phoneNumber || this.props.payOrder.payOrder.cardHolder.phone,
				email: this.state.transactionBody.email || this.props.payOrder.payOrder.cardHolder.email,
				postalCode: this.state.transactionBody.postalCode,
			},
			paymentInformation: {
				card: {
					expirationMonth: exp[0],
					expirationYear: `20${exp[1]}`,
					number: this.state.transactionBody.cardNumber,
				},
				// paymentInstrument: {
				//   id: this.state.paymentInformation.paymentInstrument
				// }
			},
			consumerAuthenticationInformation: {
				reference: this.props.payOrder.transactionIdentifier.reference,
			},
		}
	}

	prepareSaleBodyRequest() {
		const exp = this.state.transactionBody.exp.split('/')
		const country = this.state?.transactionBody?.country ? this.state.transactionBody.country.split('-') : ['', 'MX']
		let state = ['', '', '']
		let locality = ['', '', '']
		state = this.state.transactionBody.state.split('-')
		locality = this.state.transactionBody.locality.split('-')
		return {
			paymentInformation: {
				card: {
					number: this.state.transactionBody.cardNumber,
					expirationMonth: exp[0],
					expirationYear: `20${exp[1]}`,
					securityCode: this.state.transactionBody.cvv,
				},
				// instrumentIdentifier: {
				//   id: this.state.paymentInformation.instrumentIdentifier
				// },
				// paymentInstrument: {
				//   id: this.state.paymentInformation.paymentInstrument
				// }
			},
			cardHolder: {
				firstName: this.state.transactionBody.name || this.props.payOrder.payOrder.cardHolder.name,
				lastName: this.state.transactionBody.lastName || this.props.payOrder.payOrder.cardHolder.lastname,
				phone: this.state.transactionBody.phoneNumber || this.props.payOrder.payOrder.cardHolder.phone,
				email: this.state.transactionBody.email || this.props.payOrder.payOrder.cardHolder.email,
			},
			address: {
				country: country[1],
				state: state[1] || this.state.transactionBody.state,
				city: locality[2] || this.state.transactionBody.locality,
				zipCode: this.state.transactionBody.postalCode,
				street: this.state.transactionBody.address1 || this.props.payOrder.payOrder.address.street,
			},
			linkDetails: this.props.payOrder.linkDetails,
			transaction: {
				items: this.props.payOrder.payOrder.transaction.items.map((item) => ({
					totalAmount: Number(item.quantity) * Number(item.price),
					quantity: item.quantity,
					unitPrice: item.price,
					productName: item.name,
					productDescription: item.description,
					giftCardCurrency: this.props.payOrder.payOrder.transaction.currency,
				})),
				total: this.props.payOrder.payOrder.transaction.total,
				currency: this.props.payOrder.payOrder.transaction.currency,
			},
		}
	}

	async enrollmet() {
		this.setState({
			cardinalComplete: false,
		})
		// const tokenCardResponse = await tokenCard(
		//   this.props.payOrder.commerceDetails.id,
		//   this.prepareTokenBodyRequest(),
		//   this.props.payOrder.token
		// );
		// if (!tokenCardResponse.idPaymentInstrument) {
		//   this.setState({
		//     cardinalComplete: true,
		//     messageError: 'Error al comprobar, intentar ingresar otro método de pago.'
		//   });
		//   return false;
		// }
		// this.state.paymentInformation.instrumentIdentifier = tokenCardResponse.idInstrumentIdentifier;
		// this.state.paymentInformation.paymentInstrument = tokenCardResponse.idPaymentInstrument;
		enrollmetRequest(
			this.props.payOrder.commerceDetails.id,
			{
				...this.prepareBodyRequest(),
				clientReferenceInformation: {
					code: this.props.payOrder.transactionIdentifier.reference,
				},
			},
			this.props.payOrder.token
		)
			.then((result) => {
				console.log(result)
				const { consumerAuthenticationInformation, status } = result.payload
				if (consumerAuthenticationInformation?.acsUrl && status === 'PENDING_AUTHENTICATION') {
					this.AuthenticateCardinal({
						transactionId: consumerAuthenticationInformation.authenticationTransactionId,
						acsUrl: consumerAuthenticationInformation.acsUrl,
						pareq: consumerAuthenticationInformation.pareq,
					})
				} else if (status === 'AUTHENTICATION_SUCCESSFUL') {
					this.requestSale(result.payload)
				} else {
					this.setState({
						cardinalComplete: true,
						messageError: 'Error al comprobar, intentar ingresar otro método de pago.',
					})
				}
			})
			.catch(() => {
				this.setState({
					cardinalComplete: true,
					messageError: 'Error al comprobar, intentar ingresar otro método de pago.',
				})
			})
	}

	authentication(data) {
		const dataForm = this.prepareBodyRequest()
		authentication(
			this.props.payOrder.commerceDetails.id,
			{
				...dataForm,
				clientReferenceInformation: {
					code: this.props.payOrder.transactionIdentifier.reference,
				},
				consumerAuthenticationInformation: data.consumerAuthenticationInformation,
			},
			this.props.payOrder.token
		).then((result) => {
			this.requestSale(result.payload)
		})
	}

	requestSale(data3Dsecure) {
		const data = this.prepareSaleBodyRequest()
		sale(
			this.props.payOrder.commerceDetails.id,
			{
				...data,
				validateAuthentication: data3Dsecure,
			},
			this.props.payOrder.token
		).then((response) => {
			this.setState({
				modalOpen: true,
				transactionResponse: response.payload,
				cardinalComplete: true,
			})
		})
	}

	handleChangeInput(event) {
		const inputName = event.target.name
		const value = event.target.value
		if (inputName === 'cardNumber') {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
				cardType: value[0],
			})
		} else if (inputName === 'country' && inputName === 'state' && inputName === 'locality') {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
			})
		} else {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
			})
		}
	}

	handleContinueBtn = () => {
		this.InitCardinal({
			Consumer: {
				Account: {
					AccountNumber: this.state.transactionBody.cardNumber,
				},
			},
		})
		this.setState({
			lastStep: true,
		})
	}

	handleModifyPayment = () => {
		this.setState({
			lastStep: false,
			cardinalComplete: false,
			messageError: null,
		})
	}

	handleChangeInput(event) {
		const inputName = event.target.name
		const value = event.target.value
		if (inputName === 'cardNumber') {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
				cardType: value[0],
			})
		} else if (inputName === 'country' && inputName === 'state' && inputName === 'locality') {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
			})
		} else {
			this.setState({
				transactionBody: {
					...this.state.transactionBody,
					[inputName]: value,
				},
			})
		}
	}

	handleModal(status) {
		this.setState({
			modalOpen: status,
		})
	}

	render() {
		if (!this.props.payOrder?.payOrder?.transaction?.currency) {
			return
		}
		const { transactionInfo, payOrder } = this.props.payOrder

		if (transactionInfo && !this.state.lastStep) {
			return (
				<div className='checkout-3d-v2'>
					<Container maxWidth='lg'>
						<div className='Checkout3dSecurev2'>
							<div className='card-info cardholder'>
								<DataForm
									transactionBody={this.state.transactionBody}
									cardHolder={payOrder.cardHolder}
									metadata={this.props.payOrder.metadata}
									address={payOrder.address}
									inputCorrect={this.state.inputCorrect}
									currency={payOrder.transaction.currency}
									total={payOrder.transaction.total}
									onChangeInput={(event) => this.handleChangeInput(event)}
									onClickButton={this.handleContinueBtn}
								/>
							</div>
						</div>
					</Container>
				</div>
			)
		} else if (transactionInfo && this.state.lastStep) {
			return (
				<div className='checkout-3d-v2'>
					<Container maxWidth='lg'>
						<div className='Checkout3dSecurev2'>
							<div className='card-info cardholder'>
								<EndPayment lastFour={this.state.transactionBody.cardNumber?.slice(-4)} handleMod={this.handleModifyPayment} />
							</div>
						</div>
						<div className='buttons'>
							{this.state.cardinalComplete && !this.state.messageError && this.state.transactionResponse?.espiralData?.idTransactionStatus !== 1 && (
								<Button style={cardStyle.customButton} variant='contained' color='primary' className='large' onClick={() => this.enrollmet()}>
									Confirmar
								</Button>
							)}
							{!this.state.cardinalComplete && !this.state.transactionResponse && <CircularProgress />}
							{this.state.cardinalComplete && this.state.messageError && (
								<Stack sx={{ width: '100%' }} spacing={2}>
									<Alert severity='error'>{this.state.messageError}</Alert>
								</Stack>
							)}
						</div>
					</Container>
					<Modal
						aria-labelledby='transition-modal-title'
						aria-describedby='transition-modal-description'
						open={this.state.modalOpen}
						onClose={() => this.handleModal(false)}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}
						className='modal-container'>
						<Box sx={cardStyle.customBox}>
							<CheckoutResponse
								transactionResponse={{
									reference: this.state?.transactionResponse?.bank?.clientReferenceInformation?.code,
									date: this.state?.transactionResponse?.bank?.submitTimeUtc,
									pan: this.state?.transactionResponse?.data?.transaction?.pan,
									idTransactionStatus: this.state?.transactionResponse?.bank?.status === 'AUTHORIZED' ? 1 : 0,
								}}
								payOrder={this.props.payOrder.payOrder}
								distributorInfo={this.state.distributorInfo}
								transactionInfo={transactionInfo}
								errorTransaction={this.state.errorTransaction}
								total={payOrder.transaction.total}
								cardType={this.state.cardType}
							/>
						</Box>
					</Modal>
				</div>
			)
		}
	}
}

function mapStateToProps(state) {
	return {
		payOrder: state.payOrder,
	}
}

export default connect(mapStateToProps)(Checkout3dSecure)
